'use strict';

// localStorage object for objects
const ls = {};

// Eliminar entrada de localStorage
ls.remove = (key) => {
  if (Array.isArray(key)) {
    for (const i in key) {
      localStorage.removeItem(key[i]);
    }
  } else {
    localStorage.removeItem(key);
  }
};

// Crear entrada en localStorage
ls.set = (key, val) => {
  ls.remove(key);
  let value;
  try {
    value = JSON.stringify(val);
  } catch (e) {
    value = val;
  }
  localStorage.setItem(key, value);
};

// Recuperar entrada de localStorage
ls.get = (key) => {
  const val = localStorage.getItem(key);
  let value;
  try {
    value = JSON.parse(val);
  } catch (e) {
    value = val;
  }
  return value;
};

// Insertar json en localStorage
ls.bulk = (json) => {
  const values = JSON.parse(json);
  try {
    for (const [key, value] of Object.entries(values)) {
      ls.set(key, value);
    }
    return true;
  } catch (e) {
    return false;
  }
};

// Recuperar todo de localStorage
ls.all = () => {
  const all = {};
  for (let i = 0; i < localStorage.length; i++) {
    const k = localStorage.key(i);
    all[k] = JSON.parse(localStorage.getItem(k));
  }
  return all;
};

// Verificar existencia de una entrada en localStorage
ls.exist = (key) => {
  const k = localStorage.getItem(key);
  if (k === undefined || k === null || k.length === 0) {
    return false;
  }
  return true;
};

// Obtener la cantidad de items en localStorage
ls.length = () => {
  return localStorage.length;
};

// Vaciar localStorage
ls.clear = () => {
  localStorage.clear();
};

// Hash simple para ser usado como key
ls.hash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return String(Math.abs(hash));
};

// Hacer scroll hasta un elemento
const scrollTo = (element) => {
  const headerOffset = 75; // Toolbar height * font height, 3.4*22=75 y 3.4*19=65 in media < 480
  const distanceFromTop = element.offsetTop;
  const offsetPosition = distanceFromTop - headerOffset;
  document.documentElement.scrollTop = offsetPosition;
};

// Obtener una fecha formateada a partir de un Unix Timestamp
const getDate = (ts, short) => {
  const date = new Date(ts);
  const y = new Intl.DateTimeFormat('es-VE', { year: 'numeric' }).format(date);
  const mm = new Intl.DateTimeFormat('es-VE', { month: 'long' }).format(date);
  const m = new Intl.DateTimeFormat('es-VE', { month: '2-digit' }).format(date);
  const d = new Intl.DateTimeFormat('es-VE', { day: '2-digit' }).format(date);
  const h = new Intl.DateTimeFormat('es-VE', { hour: '2-digit', hour12: false }).format(date);
  const i = new Intl.DateTimeFormat('es-VE', { minute: '2-digit' }).format(date);

  if (short) {
    return `${y}${m}${d}${h.padStart(2, '0')}${i.padStart(2, '0')}`;
  } else {
    return `${d} de ${mm} de ${y} a las ${h.padStart(2, '0')}:${i.padStart(2, '0')} hrs`;
  }
};

// String cleaner
const clean = (text) => {
  // const regex = /([^ \(\)\{\}\[\]\.\-\?¿¡!~`@#$%&*_=+"',:;0-9\p{L}-]+)/iug;
  return text.replace(/[ ]{2,}/, ' ');
};

export { clean, getDate, ls, scrollTo };
