'use strict';
import _hyperscript from './_hyperscript/_hyperscript.js'; // https://hyperscript.org/
import './qrcode/qrcode.js'; // https://github.com/Intosoft/qrcode
import { ls } from './ls.js';

_hyperscript.browserInit();

const urlProtocol = window.location.protocol;
const urlHostname = window.location.host;
const urlPathname = window.location.pathname;
const volumeName = window.location.pathname.split('/')[1];
const volumeHash = ls.hash(volumeName);
const goodBlocks = [
  'p',
  'div',
];
const topBlocks = [
  'html',
  'head',
  'body',
  'header',
  'nav',
  'aside',
  'main',
  'pre',
  'section',
  'footer',
];

let urlFragment = '';
let selectionUrl = '';
let startOffset = '';
let endOffset = '';
let startXPath = '';
let endXPath = '';

/**
 * A non-cryptographic hash function, which is probably good enough for generating unique-ish ids.
 *
 * source: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/52171480#52171480
 * optimized by: https://github.com/WesleyAC/deeplinks
 *
 * @param {string} str the string to hash
 * @returns {number} a number that is probably unique for different strings
 * @throws {string} if the string is not a string
 */
const cyrb53 = (str) => {
  let h1 = 0xdeadbeef, h2 = 0x41c6ce57;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/**
 * Converts a number to a string in base 64, using a reasonable subset of characters for a url.
 * This cannot handle negative numbers and only works on the integer part, discarding the fractional part.
 *
 * source: https://stackoverflow.com/questions/6213227/fastest-way-to-convert-a-number-to-radix-64-in-javascript/6573119#6573119
 * optimized by: https://github.com/WesleyAC/deeplinks
 *
 * @param {number} number the number to convert
 * @returns {string} the string representation of the number in base 64
 * @throws {string} if the number is negative, NaN, or Infinity
 */
const fromNumber = (number) => {
  const rixits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_';
  if (isNaN(number) || number === Infinity || number < 0) throw 'invalid input';

  let result = '', rixit; // like 'digit', only in some non-decimal radix
  number = Math.floor(number);
  for (;;) {
    rixit = number % 64;
    result = rixits.charAt(rixit) + result;
    number = Math.floor(number / 64);
    if (number == 0) break;
  }
  return result;
};

/**
 * Converts a base 64 string to a number. Uses a customized set of characters for base 64 encoding.
 *
 * @param {string} string - The base 64 string to convert.
 * @returns {number} The numeric representation of the base 64 string.
 */
const toNumber = (string) => {
  let result = 0;
  const rixits = string.split('');
  for (let e = 0; e < rixits.length; e++) {
    result = (result * 64) + rixits.indexOf(rixits[e]);
  }
  return result;
};

/**
 * Converts an XPath string to a unique-ish string using a non-cryptographic hash
 * function. This is used for generating unique-ish ids for deeplinks.
 *
 * @param {string} str - The XPath string to convert.
 * @returns {string} A unique-ish string based on the XPath string.
 * @throws {string} if the string is not a string
 */
const hash = (str) => {
  return fromNumber(cyrb53(str.toString().trim()));
};

/**
 * Generates a QR code for the given URL and puts it in the #qr-container element.
 * The QR code is configured to be 400x400 pixels, with a transparent background
 * and a foreground color of #015F6B.
 *
 * @param {string} url - The URL to encode in the QR code.
 * @returns {void}
 */
const qr = (url) => {
  const config = {
    'length': 400,
    'padding': 0,
    'errorCorrectionLevel': 'H',
    'value': url,
    'logo': {
      'url': '',
      'size': 2,
      'removeBg': false,
    },
    'shapes': {
      'eyeFrame': 'circle',
      'body': 'square',
      'eyeball': 'circle',
    },
    'colors': {
      'background': 'transparent',
      'body': 'rgba(1, 95, 107, 1)',
      'eyeFrame': {
        'topLeft': 'rgba(136, 136, 136, 1)',
        'topRight': 'rgba(136, 136, 136, 1)',
        'bottomLeft': 'rgba(136, 136, 136, 1)',
      },
      'eyeball': {
        'topLeft': 'body',
        'topRight': 'body',
        'bottomLeft': 'body',
      },
    },
  };
  const svgString = window.qrcode.generateSVGString(config);
  document.getElementById('qr-container').innerHTML = svgString;
};

/**
 * Converts an element to an XPath string.
 *
 * @param {Element} element The element to convert.
 * @returns {string} An XPath string that identifies the element.
 * @throws {string} if the element is not an element
 */
const getXPath = (element) => {
  let comp;
  const comps = [];
  let xpath = '';
  const getPos = function (node) {
    let position = 1;
    let curNode;
    if (node.nodeType === Node.ATTRIBUTE_NODE) {
      return null;
    }
    for (curNode = node.previousSibling; curNode; curNode = curNode.previousSibling) {
      if (curNode.nodeName === node.nodeName) {
        ++position;
      }
    }
    return position;
  };
  if (element instanceof Document) {
    return '/';
  }
  for (; element && !(element instanceof Document); element = element.nodeType === Node.ATTRIBUTE_NODE ? element.ownerElement : element.parentNode) {
    comp = comps[comps.length] = {};
    switch (element.nodeType) {
      case Node.TEXT_NODE:
        comp.name = 'text()';
        break;
      case Node.ATTRIBUTE_NODE:
        comp.name = '@' + element.nodeName;
        break;
      case Node.PROCESSING_INSTRUCTION_NODE:
        comp.name = 'processing-instruction()';
        break;
      case Node.COMMENT_NODE:
        comp.name = 'comment()';
        break;
      case Node.ELEMENT_NODE:
        comp.name = element.nodeName;
        break;
    }
    comp.position = getPos(element);
  }
  for (let i = comps.length - 1; i >= 0; i--) {
    comp = comps[i];
    xpath += '/' + comp.name;
    if (comp.position != null) {
      xpath += '[' + comp.position + ']';
    }
  }
  return xpath;
};

/**
 * Evaluates an XPath expression and returns the first matching element.
 *
 * @param {string} xpath The XPath expression to evaluate.
 * @returns {Element} The first matching element, or null if no matching element is found.
 */
const getElementByXpath = (xpath) => {
  return document.evaluate(
    xpath,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null,
  ).singleNodeValue;
};

/**
 * Returns an array of all annotations stored in local storage, sorted by startXPath.
 *
 * @returns {Array} An array of objects, each with startXPath and endXPath properties, sorted by startXPath.
 */
const getStoredAnnotationsArray = () => {
  const annotationsArray = new Array();
  Object.keys(localStorage).forEach((key) => {
    if (!key.includes(`annotation_${volumeHash}_`)) return;
    const entry = ls.get(key);
    annotationsArray.push(entry.position);
  });

  // Ordenamiento del array de rangos
  return annotationsArray.slice().sort((a, b) => {
    try {
      const A = document.evaluate(a.startXPath, document, null, XPathResult.ANY_TYPE, null);
      const B = document.evaluate(b.startXPath, document, null, XPathResult.ANY_TYPE, null);

      const AA = A.iterateNext();
      const BB = B.iterateNext();

      if (!AA && !BB) return 0; // Both expressions didn't match anything
      if (!AA) return 1; // Only b matched
      if (!BB) return -1; // Only a matched

      const position = AA.compareDocumentPosition(BB);

      if (position & Node.DOCUMENT_POSITION_FOLLOWING || position & Node.DOCUMENT_POSITION_CONTAINED_BY) {
        return 1;
      } else if (position & Node.DOCUMENT_POSITION_PRECEDING || position & Node.DOCUMENT_POSITION_CONTAINS) {
        return -1;
      } else {
        if (a.startOffset < b.startOffset) {
          return 1;
        } else if (a.startOffset > b.startOffset) {
          return -1;
        } else {
          return 0;
        }
      }
    } catch (error) {
      console.error(`Error evaluating XPath: ${error.message}`);
      return 0; // Treat errors as equal
    }
  });
};

const getNodesInRange = (range) => {
  const nodes = [];

  // Helper function to add node or part of a text node to the array
  const addNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE && !range.intersectsNode(node)) return;

    if (node.nodeType !== Node.ELEMENT_NODE || node.childNodes.length === 0) {
      nodes.push(node);
    } else {
      for (let child of node.childNodes) {
        const intersection = range.comparePoint(child, 0);

        // If the start point is after this node or end point is before this node
        if (
          (intersection & Range.END_TO_START && !range.intersectsNode(child)) ||
          (intersection & Range.START_TO_END && !range.intersectsNode(child))
        ) {
          continue;
        }
        addNode(child);
      }
    }
  };

  // If the range starts and ends in the same text node, handle it separately
  if (range.startContainer === range.endContainer && range.startOffset !== range.endOffset) {
    const clonedRange = document.createRange();
    clonedRange.setStart(range.startContainer, range.startOffset);
    clonedRange.setEnd(range.startContainer, range.endOffset);

    nodes.push(clonedRange.cloneContents().firstChild);
  } else {
    // Traverse from the start node to the end node
    let currentNode = range.commonAncestorContainer;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    //: Restringir el walk a mainMatter solamente
    const walker = document.createTreeWalker(currentNode, NodeFilter.SHOW_ALL);

    while (walker.nextNode()) {
      const node = walker.currentNode;

      // Check if the current node is within the range
      if (
        (node.nodeType === Node.TEXT_NODE && !range.intersectsNode(node)) ||
        (!range.isPointInRange(node, 0) &&
          !range.isPointInRange(node, node.childNodes.length))
      ) continue;

      addNode(node);
    }
  }
  return nodes;
};

const showStoredAnnotations = () => {
  const storedAnnotationsArray = getStoredAnnotationsArray();
  //const annotationRanges = new Array();

  const highlightNode = (node) => {
    const span = document.createElement('span');
    span.className = 'annotation';
    Array.from(node.attributes).forEach((a) => {
      span.setAttribute(a.name, a.value);
    });
    while (node.firstChild) span.appendChild(node.firstChild);
    node.parentNode.replaceChild(span, node);
  };

  const highlightRange = (range) => {
    const extractedContent = range.extractContents();
    const span = document.createElement('span');
    span.className = 'annotation';
    // span.id = key;
    span.appendChild(extractedContent);
    range.insertNode(span);
  };

  storedAnnotationsArray.forEach((annotation) => {
    const range = document.createRange();
    const startNode = getElementByXpath(annotation.startXPath);
    const endNode = annotation.endXPath ? getElementByXpath(annotation.endXPath) : startNode;

    const startNodeText = startNode.nodeValue;
    const startNodeLength = startNodeText.length;
    const endNodeText = endNode.nodeValue;
    const endNodeLength = endNodeText.length;

    range.setStart(startNode, annotation.startOffset);
    range.setEnd(endNode, annotation.endOffset);

    if (range.commonAncestorContainer.nodeName === 'SECTION') {
      // console.log('La selección coge varios elementos bloque');
      getNodesInRange(range).forEach((node) => {
        // highlightNode(node);
        // console.log(node);
      });
    } else {
      highlightRange(range);
    }

    // annotationRanges.push(range);

    // console.log('================================================');
    // console.log(range.toString());
    // console.log(range.commonAncestorContainer.nodeName);
    // console.log(getNodesInRange(range));
    // console.log(':: ', range.toString(), ' ::');
    // console.log('start:');
    // console.log(startNodeText);
    // console.log('start length: ', startNodeLength, ' / offset: ', annotation.startOffset);
    // console.log('end:');
    // console.log(endNodeText);
    // console.log('end length: ', endNodeLength, ' / offset: ', annotation.endOffset);
    // window.getSelection().addRange(range);
  });
};

/**
 * Process the current text selection, creates the fragment for
 *  it and pops up the dialog with the different options.
 *
 * @returns {void}
 */
const prepareTextSelection = (docSelection) => {
  // console.log('rangeCount', docSelection.type);
  const range = docSelection.getRangeAt(0);

  if (!range.toString().trim()) return;
  if (event.button > 0) return;

  const startContainer = range.startContainer;
  startOffset = range.startOffset.toString();
  const endContainer = range.endContainer;
  endOffset = range.endOffset.toString();

  const shortSel = startContainer === endContainer;

  startXPath = getXPath(startContainer);
  const startXPathHash = hash(startXPath);

  endXPath = shortSel ? null : getXPath(endContainer);
  const endXPathHash = !endXPath ? '' : hash(endXPath);

  const colon = shortSel ? '' : ':';

  urlFragment = `#${startXPathHash}:${startOffset}${colon}${endXPathHash}:${endOffset}`;
  selectionUrl = urlProtocol + '//' + urlHostname + urlPathname + urlFragment;

  qr(selectionUrl);

  // La posición del marker más cercana al texto seleccionado:
  //markerPosition = getParentBlock(rangeZero.startContainer.parentElement) || rangeZero.startContainer.parentElement;

  // El modal con las opciones de anotación:
  document.getElementById('selection-dialog').showModal();
};

/**
 * Stores the current text selection in the localStorage with
 *  the hash of the fragment as key. The stored object has the
 *  following properties:
 *
 *  - protocol: the protocol of the current URL
 *  - host: the host of the current URL
 *  - path: the path of the current URL
 *  - fragment: the fragment of the current URL
 *  - url: the complete URL of the current selection
 *  - timestamp: the timestamp of the moment the annotation was made
 *  - annotation: the text of the annotation
 *
 * @returns {void}
 */
const storeAnnotation = () => {
  const annotationHash = ls.hash(urlFragment);
  const annotationKey = `annotation_${volumeHash}_${annotationHash}`;
  const annotationText = document.getElementById('selection-annotation-text').value.trim();

  ls.set(annotationKey, {
    annotation: annotationText,
    protocol: urlProtocol,
    host: urlHostname,
    path: urlPathname,
    position: {
      startXPath,
      startOffset,
      endXPath,
      endOffset,
    },
    fragment: urlFragment,
    url: selectionUrl,
    timestamp: Date.now(),
  });

  // TODO: hacer innecesario el reload
  location.reload();
  //showStoredAnnotations();
  //document.getElementById('selection-dialog').close();

  // TODO: hacer que el ícono de anotaciones destelle y muestre o actualice el badge con el número de anotaciones almacenadas
};

/**
 * load event
 */
window.addEventListener('load', () => {
  /**
   * pointerup event in mainMatter
   */
  const mainMatter = document.getElementById('main-matter');
  mainMatter.addEventListener('pointerup', (event) => {
    return; //! TEMPORAL
    event.stopImmediatePropagation();
    event.stopPropagation();

    const docSelection = document.getSelection();
    if (docSelection.rangeCount) prepareTextSelection(docSelection);
  });

  /**
   * click event que guarda una anotación
   */
  const storeAnnotationButton = document.getElementById('store-annotation');
  storeAnnotationButton.addEventListener('click', (event) => {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    storeAnnotation();
  });

  // load stored annotations
  showStoredAnnotations();
});

/**
 * esta verga puede ser innecesaria, vamo' a ve...
 */
void (async () => {
  const fragment = location.hash.slice(1);
  if (fragment && !document.getElementById(fragment)) {
    selectRanges(fragmentToRangeList(fragment));
  }
})();

/**
 * si el nuevo rango pisa otras etiquetas, se crean rangos diferentes dentro de cada etiqueta
 *
 * el nuevo rango se crea con:
 * * nodo inicial = nodo cogido - nro de spans previos
 * * offset inicial = nro de caracteres cogidos + nro de caracteres dentro de los span previos
 * * nodo final = nodo cogido + nro de spans previos
 * * offset final = nro de caracteres cogidos + nro de caracteres dentro de los span previos
 */
